/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: poppins !important;
}

.floating-icons {
  position: fixed;
  bottom: 10%;
  right: 0%;
  transform: translateX(-20%);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1000;
}

.floating-icons a:hover {
  text-decoration: none !important;
}
.icon {
  margin-top: 10px;
  font-size: 25px;
  color: #000; /* Change the color as needed */
}

.icon {
  animation: zoomInOut 1s infinite alternate; /* Apply the zoomInOut animation */
}

@keyframes zoomInOut {
  0% {
    transform: scale(1); /* Start with normal size */
  }
  50% {
    transform: scale(1.1); /* Zoom in */
  }
  100% {
    transform: scale(1); /* Zoom out */
  }
}

.red_fixed_button i {
  font-size: 18px;
}
.red_fixed_button {
  border-radius: 5px;
  /* box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px rgba(0, 0, 0, 0.075); */
  color: #fff;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 10px;
  border: none;
  background-color: #ff3131;
  display: flex;
  align-items: center;
  width: 135px;
  justify-content: space-around;
}

.green_fixed_button {
  border-radius: 5px;
  /* box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px rgba(0, 0, 0, 0.075); */
  color: #fff;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px;
  border: none;
  background-color: #25d366;
  display: flex;
  align-items: center;
  width: 135px;
  justify-content: space-around;
}
.green_fixed_button i {
  font-size: 22px;
}

.home-container {
  position: relative;
  /* Add any other styling for your container */
}

.preloader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  /* Add any other styling for your preloader container */
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.wrapper .loader {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
.loader .loading {
  background: #ff9232;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  margin: 0 10px;
  animation: load 0.7s ease infinite;
}
.loader .loading.one {
  animation-delay: 0.3s;
}
.loader .loading.two {
  animation-delay: 0.4s;
}
.loader .loading.three {
  animation-delay: 0.5s;
}
@keyframes load {
  0% {
    width: 30px;
    height: 30px;
  }
  50% {
    width: 20px;
    height: 20px;
  }
}

.owl-carousel {
    display: block  !important; 
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    z-index: 1;
}
