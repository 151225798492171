@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.ser_sec1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../public/images/services_banner.png");
  /* background-image: url("../../public/images/services_banner.png"); */
  height: 50vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-family: poppins !important;
  color: #fff;
}

.ser_sec2 .container {
  padding-top: 100px;

}


.ser_sec2 {
  font-family: poppins !important;
  padding-bottom: 90px;
}

.ser_sec2 p {
  padding-top: 20px;
  color: #8a8a8a;
}
@media screen and (min-width: 0px) and (max-width: 600px) {
  .ser_sec2 p {
    text-align: justify;
  }
}
.img-wrapper {
  overflow: hidden !important;
}

.inner-img {
  transition: 0.7s;
}

.inner-img:hover {
  transform: scale(1.1);
  cursor: pointer;
}
